import { SpotifyPlaylist } from "../hooks/useSpotifyPlaylist";
import { SpotifyPlaylistTrack } from "../hooks/useSpotifyPlaylistTracks";
import { getApiKey } from "../utils/auth";
import { SpotifyPlaylistRoute, SpotifyPlaylistTracksRoute } from "./routes";

export const QueryKey = "spotify-playlist";
export const QueryKeyTracks = "spotify-playlist-tracks";

export const fetchSpotifyPlaylist = async (
  idOrUrl: string
): Promise<SpotifyPlaylist | null> => {
  return fetch(
    `${
      process.env.REACT_APP_SERVER_URL
    }${SpotifyPlaylistRoute}/${encodeURIComponent(idOrUrl)}`,
    {
      headers: { Authorization: `Bearer ${getApiKey()}` },
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};

export const fetchSpotifyPlaylistTracks = async (
  idOrUrl: string
): Promise<SpotifyPlaylistTrack[]> => {
  return fetch(
    `${
      process.env.REACT_APP_SERVER_URL
    }${SpotifyPlaylistTracksRoute}/${encodeURIComponent(idOrUrl)}`,
    {
      headers: { Authorization: `Bearer ${getApiKey()}` },
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return [];
      }
    })
    .catch(() => {
      return [];
    });
};
