import { PlaylistContact } from "../hooks/usePlaylistContact";
import { getApiKey } from "../utils/auth";
import { PlaylistContactRoute } from "./routes";

export const QueryKey = "playlists-contact";

export type CreateContactDto = {
  name: string;
  email?: string | null;
  phone?: string | null;
  other?: string | null;
};

export type UpdateContactDto = {
  id: string;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  other?: string | null;
};

export const fetchPlaylistContact = async (
  id: string
): Promise<PlaylistContact | null> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${PlaylistContactRoute}/${id}`,
    {
      headers: { Authorization: `Bearer ${getApiKey()}` },
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};

export const updatePlaylistContact = async (
  updatePlaylistContactDto: UpdateContactDto
): Promise<void> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${PlaylistContactRoute}/${updatePlaylistContactDto.id}`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${getApiKey()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: updatePlaylistContactDto.name,
        email: updatePlaylistContactDto.email,
        phone: updatePlaylistContactDto.phone,
        other: updatePlaylistContactDto.other,
      }),
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};
