import ReactApexChart from "react-apexcharts";
import { memo } from "react";
import usePlaylistData from "../../hooks/usePlaylistData";
import { ApexOptions } from "apexcharts";
import { subDays, differenceInDays } from "date-fns";

interface FollowersDataProps {
  id?: string;
}

const FollowersData: React.FC<FollowersDataProps> = ({ id }) => {
  const { playlistData } = usePlaylistData(id);

  // Set the initial window to past month or all data if not 1 month of data yet
  const timestamps = playlistData.map((d) => new Date(d.timestamp).getTime());
  const maxDateTimestamp = Math.max(...timestamps);
  const minDateTimestamp = Math.min(...timestamps);
  const maxDate = new Date(maxDateTimestamp);
  const minDate =
    differenceInDays(maxDate, new Date(minDateTimestamp)) < 30
      ? new Date(minDateTimestamp)
      : subDays(maxDate, 30);

  const options: ApexOptions = {
    chart: {
      height: 350,
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Followers",
      //   align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      type: "datetime",
      min: minDate.getTime(),
      max: maxDate.getTime(),
    },
    yaxis: {
      labels: {
        formatter: (value: number): string =>
          Math.round(value).toLocaleString(),
      },
      tickAmount: 5,
      decimalsInFloat: 0,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm:ss", // this is a standard date-time format
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={[
          {
            name: "Followers",
            data: playlistData.map((d) => [
              new Date(d.timestamp).getTime(),
              d.followers,
            ]),
          },
        ]}
        type="line"
        height={350}
      />
    </div>
  );
};

export default memo(FollowersData);
