export const getPlaylistStatusStyles = (status: string) => {
  switch (status.toLowerCase()) {
    case "success":
      return "bg-green-50 text-green-700 ring-green-600/20";
    case "error":
      return "bg-red-50 text-red-700 ring-red-600/20";
    default:
      return "bg-gray-50 text-gray-700 ring-gray-600/20";
  }
};

export const getPlaylistActiveStatusStyles = (isActive: boolean) => {
  return isActive
    ? "bg-green-50 text-green-700 ring-green-600/20"
    : "bg-gray-50 text-gray-700 ring-gray-600/20";
};

export const getChangeStyles = (percentChange: number) => {
  if (percentChange === 0) {
    return "text-gray-500";
  } else if (percentChange > 0) {
    return "text-green-500";
  } else {
    return "text-red-500";
  }
};
