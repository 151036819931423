import { Fragment, memo, useEffect, useMemo, useState } from "react";
import usePlaylists, { Playlist } from "../../hooks/usePlaylists";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/20/solid";
import DeletePlaylistModal from "../Modals/DeletePlaylistModal";
import PlaylistsTableRow from "./PlaylistsTableRow";
import AddPlaylistsModal from "../Modals/AddPlaylistsModal";
import { useSearchContext } from "../../context/SearchContextProvider";
import ManualDataPullModal from "../Modals/ManualDataPullModal";
import { Menu, Transition } from "@headlessui/react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const PlaylistsTable: React.FC = () => {
  const { playlists } = usePlaylists();
  const {
    tab,
    searchText,
    hasFilterApplied,
    reset: resetSearch,
    setTab,
  } = useSearchContext();
  const [sortColumn, setSortColumn] = useState<keyof Playlist>(
    "numFollowersChange24hr"
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [openAddPlaylistModal, setOpenAddPlaylistModal] =
    useState<boolean>(false);
  const [openManualDataPullModal, setOpenManualDataPullModal] =
    useState<boolean>(false);
  const [openDeleteModalPlaylist, setOpenDeleteModalPlaylist] = useState<
    Playlist | undefined
  >(undefined);

  const filteredPlaylists = useMemo(() => {
    if (hasFilterApplied) {
      const caseInsensitiveSearchText = searchText.toLowerCase();

      return playlists.filter(
        (p) =>
          (tab === "favorites" ? p.isFavorite : true) &&
          (p.name.toLowerCase().includes(caseInsensitiveSearchText) ||
            p.lastStatus.toLowerCase().includes(caseInsensitiveSearchText) ||
            (p.isActive ? "active" : "paused").includes(
              caseInsensitiveSearchText
            ) ||
            p.tags
              .map((t) => t.toLowerCase())
              .find((t) => t.includes(caseInsensitiveSearchText)) ||
            p.contactName?.toLowerCase().includes(caseInsensitiveSearchText) ||
            p.contactEmail?.toLowerCase().includes(caseInsensitiveSearchText) ||
            p.contactOther?.toLowerCase().includes(caseInsensitiveSearchText))
      );
    } else {
      return playlists;
    }
  }, [tab, searchText, playlists, hasFilterApplied]);

  const sortedPlaylists = useMemo(() => {
    const _sortDirection = sortDirection === "asc" ? 1 : -1;

    switch (sortColumn) {
      case "followers":
        return filteredPlaylists.sort((a, b) =>
          (a.followers ?? 0) > (b.followers ?? 0)
            ? _sortDirection
            : _sortDirection * -1
        );
      case "streams":
        return filteredPlaylists.sort((a, b) =>
          (a.streams ?? 0) > (b.streams ?? 0)
            ? _sortDirection
            : _sortDirection * -1
        );
      case "lastStatus":
        return filteredPlaylists.sort((a, b) =>
          a.lastStatus > b.lastStatus ? _sortDirection : _sortDirection * -1
        );
      case "numFollowersChange24hrPercent":
        return filteredPlaylists.sort((a, b) =>
          a.numFollowersChange24hrPercent > b.numFollowersChange24hrPercent
            ? _sortDirection
            : _sortDirection * -1
        );
      case "numFollowersChange24hr":
        return filteredPlaylists.sort((a, b) =>
          a.numFollowersChange24hr > b.numFollowersChange24hr
            ? _sortDirection
            : _sortDirection * -1
        );
      case "isActive":
        return filteredPlaylists.sort((a, b) =>
          (a.isActive ? "active" : "paused") >
          (b.isActive ? "active" : "paused")
            ? _sortDirection
            : _sortDirection * -1
        );
      case "pullSchedule":
        return filteredPlaylists.sort((a, b) =>
          a.pullSchedule > b.pullSchedule ? _sortDirection : _sortDirection * -1
        );
      case "createdAt":
        return filteredPlaylists.sort((a, b) =>
          new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
            ? _sortDirection
            : _sortDirection * -1
        );
      case "ownerName":
        return filteredPlaylists.sort((a, b) =>
          a.ownerName > b.ownerName ? _sortDirection : _sortDirection * -1
        );
      case "name":
      default:
        return filteredPlaylists.sort((a, b) =>
          a.name > b.name ? _sortDirection : _sortDirection * -1
        );
    }
  }, [filteredPlaylists, sortColumn, sortDirection]);

  const SortIcon = useMemo(
    () => (sortDirection === "asc" ? ChevronUpIcon : ChevronDownIcon),
    [sortDirection]
  );

  const handleShowMostRecent = () => {
    setTab("recent");
    setSortDirection("desc");
    setSortColumn("createdAt");
  };

  const handleShowAll = () => {
    resetSearch();
    setSortDirection("desc");
    setSortColumn("numFollowersChange24hr");
  };

  const handleShowFavorites = () => {
    setTab("favorites");
  };

  const handleToggleSortDirection = (column: keyof Playlist) => {
    if (column === sortColumn) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortDirection("asc");
    }

    if (tab === "recent") {
      setTab("all");
    }

    setSortColumn(column);
  };

  // Reset search text everytime this component mounts
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetSearch(), []);

  return (
    <>
      <div className="px-4 pt-6 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Playlists
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the playlists and their current status. Click on a
              playlist to view all data.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 flex items-center gap-2">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setOpenAddPlaylistModal(true)}
            >
              Add playlist
            </button>
            <div className="sm:flex sm:items-baseline sm:justify-between">
              <div className="flex items-center justify-between sm:flex-shrink-0 sm:justify-start">
                <Menu as="div" className="relative ml-3 inline-block text-left">
                  <div>
                    <Menu.Button className="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex w-full justify-between px-4 py-2 text-sm"
                              )}
                              onClick={() => setOpenManualDataPullModal(true)}
                            >
                              <span>Manual data pull</span>
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-8 mb-2">
          <div className="flex gap-2 ">
            <div
              onClick={handleShowAll}
              className={`${
                tab === "all" && !searchText
                  ? "border-indigo-500 text-gray-900"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
              } text-lg cursor-pointer inline-flex items-center border-b-2 px-1 pt-1 font-medium`}
            >
              All
            </div>
            <div
              onClick={handleShowFavorites}
              className={`${
                tab === "favorites"
                  ? "border-indigo-500 text-gray-900"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
              } text-lg cursor-pointer inline-flex items-center border-b-2 px-1 pt-1 font-medium`}
            >
              Favorites
            </div>
            <div
              onClick={handleShowMostRecent}
              className={`${
                tab === "recent"
                  ? "border-indigo-500 text-gray-900"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
              } text-lg cursor-pointer inline-flex items-center border-b-2 px-1 pt-1 font-medium`}
            >
              Most Recent
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Playlists shown:
            </h1>
            <p className="text-md text-gray-700">
              {sortedPlaylists.length.toLocaleString()}
            </p>
          </div>
        </div>
        <div className="flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      <div className="group inline-flex">
                        Name
                        <span
                          onClick={() => handleToggleSortDirection("name")}
                          className={`${
                            sortColumn !== "name" && "invisible"
                          } ml-2 cursor-pointer flex-none rounded bg-gray-100 text-gray-500 group-hover:bg-gray-200 group-hover:visible group-focus:visible`}
                        >
                          <SortIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      <div className="group inline-flex">
                        Owner
                        <span
                          onClick={() => handleToggleSortDirection("ownerName")}
                          className={`${
                            sortColumn !== "ownerName" && "invisible"
                          } ml-2 cursor-pointer flex-none rounded bg-gray-100 text-gray-500 group-hover:bg-gray-200 group-hover:visible group-focus:visible`}
                        >
                          <SortIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Followers
                        <span
                          onClick={() => handleToggleSortDirection("followers")}
                          className={`${
                            sortColumn !== "followers" && "invisible"
                          } ml-2 cursor-pointer flex-none rounded bg-gray-100 text-gray-500 group-hover:bg-gray-200 group-hover:visible group-focus:visible`}
                        >
                          <SortIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Daily Streams
                        <span
                          onClick={() => handleToggleSortDirection("streams")}
                          className={`${
                            sortColumn !== "streams" && "invisible"
                          } ml-2 cursor-pointer flex-none rounded bg-gray-100 text-gray-500 group-hover:bg-gray-200 group-hover:visible group-focus:visible`}
                        >
                          <SortIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        24 hr Change (%)
                        <span
                          onClick={() =>
                            handleToggleSortDirection(
                              "numFollowersChange24hrPercent"
                            )
                          }
                          className={`${
                            sortColumn !== "numFollowersChange24hrPercent" &&
                            "invisible"
                          } ml-2 cursor-pointer flex-none rounded bg-gray-100 text-gray-500 group-hover:bg-gray-200 group-hover:visible group-focus:visible`}
                        >
                          <SortIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        24 hr Change (#)
                        <span
                          onClick={() =>
                            handleToggleSortDirection("numFollowersChange24hr")
                          }
                          className={`${
                            sortColumn !== "numFollowersChange24hr" &&
                            "invisible"
                          } ml-2 cursor-pointer flex-none rounded bg-gray-100 text-gray-500 group-hover:bg-gray-200 group-hover:visible group-focus:visible`}
                        >
                          <SortIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Schedule
                        <span
                          onClick={() =>
                            handleToggleSortDirection("pullSchedule")
                          }
                          className={`${
                            sortColumn !== "pullSchedule" && "invisible"
                          } ml-2 cursor-pointer flex-none rounded bg-gray-100 text-gray-500 group-hover:bg-gray-200 group-hover:visible group-focus:visible`}
                        >
                          <SortIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Status
                        <span
                          onClick={() =>
                            handleToggleSortDirection("lastStatus")
                          }
                          className={`${
                            sortColumn !== "lastStatus" && "invisible"
                          } ml-2 cursor-pointer flex-none rounded bg-gray-100 text-gray-500 group-hover:bg-gray-200 group-hover:visible group-focus:visible`}
                        >
                          <SortIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Active
                        <span
                          onClick={() => handleToggleSortDirection("isActive")}
                          className={`${
                            sortColumn !== "isActive" && "invisible"
                          } ml-2 cursor-pointer flex-none rounded bg-gray-100 text-gray-500 group-hover:bg-gray-200 group-hover:visible group-focus:visible`}
                        >
                          <SortIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {sortedPlaylists.map((playlist) => (
                    <PlaylistsTableRow
                      key={playlist.id}
                      playlist={playlist}
                      setOpenDeleteModalPlaylist={setOpenDeleteModalPlaylist}
                    />
                  ))}
                </tbody>
              </table>
              {sortedPlaylists.length === 0 && (
                <div className="min-w-100 flex justify-center">
                  <p className="mt-4 text-sm text-gray-700">
                    {hasFilterApplied ? (
                      <>No playlists match. Please remove filter</>
                    ) : (
                      <>
                        No playlist data. Please reload the page or add a
                        playlist using the button above.
                      </>
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <DeletePlaylistModal
        playlist={openDeleteModalPlaylist}
        close={() => setOpenDeleteModalPlaylist(undefined)}
      />
      <AddPlaylistsModal
        show={openAddPlaylistModal}
        close={() => setOpenAddPlaylistModal(false)}
      />
      <ManualDataPullModal
        show={openManualDataPullModal}
        close={() => setOpenManualDataPullModal(false)}
      />
    </>
  );
};

export default memo(PlaylistsTable);
