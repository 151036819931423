import { Playlist } from "../hooks/usePlaylists";
import { getApiKey } from "../utils/auth";
import { CreateContactDto } from "./playlistContact";
import { BulkPlaylistsRoute, PlaylistsRoute } from "./routes";

export const QueryKey = "playlists";

export type AddMultiplePlaylistsDto = {
  playlists: AddPlaylistDto[];
};

export type AddPlaylistDto = {
  spotifyUrl: string;
  contact?: CreateContactDto;
};

export type UpdatePlaylistDto = {
  id: string;
  isActive?: boolean;
  isFavorite?: boolean;
  pullSchedule?: number;
};

export const fetchPlaylists = async (): Promise<Playlist[]> => {
  return fetch(`${process.env.REACT_APP_SERVER_URL}${PlaylistsRoute}`, {
    headers: { Authorization: `Bearer ${getApiKey()}` },
  })
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return [];
      }
    })
    .catch(() => {
      return [];
    });
};

export const addPlaylist = async (
  addPlaylistDto: AddPlaylistDto
): Promise<void> => {
  return fetch(`${process.env.REACT_APP_SERVER_URL}${PlaylistsRoute}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getApiKey()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      playlist: addPlaylistDto.spotifyUrl,
      contact: addPlaylistDto.contact,
    }),
  })
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return [];
      }
    })
    .catch(() => {
      return [];
    });
};

export const updatePlaylist = async (
  updatePlaylistDto: UpdatePlaylistDto
): Promise<void> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${PlaylistsRoute}/${updatePlaylistDto.id}`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${getApiKey()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        isActive: updatePlaylistDto.isActive,
        isFavorite: updatePlaylistDto.isFavorite,
        pullSchedule: updatePlaylistDto.pullSchedule,
      }),
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};

/**
 * Bulk add multiple playlists at once
 * @param {playlists} - list of create playlist DTOs
 * @returns number of playlists in array
 */
export const bulkAddPlaylists = async ({
  playlists,
}: AddMultiplePlaylistsDto): Promise<number> => {
  return fetch(`${process.env.REACT_APP_SERVER_URL}${BulkPlaylistsRoute}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getApiKey()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ playlists }),
  })
    .then(async (res) => {
      if (res.ok) {
        return playlists.length;
      } else {
        return 0;
      }
    })
    .catch(() => {
      return 0;
    });
};

export const deletePlaylist = async (id: string): Promise<boolean> => {
  return fetch(`${process.env.REACT_APP_SERVER_URL}${PlaylistsRoute}/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${getApiKey()}`,
    },
  })
    .then(async (res) => {
      if (res.ok) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });
};
