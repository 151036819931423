import { useMemo } from "react";
import useApiKey from "../hooks/useApiKey";
import Redirect from "../components/Redirect";
import { PAGES } from "../consts";

function withAuthorization<T>(
  WrappedComponent: React.ComponentType<T>
): React.FC<React.PropsWithChildren<T>> {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  // Creating the inner component. The calculated Props type here is where the magic happens.
  const ComponentWithAuthorization: React.FC<React.PropsWithChildren<T>> = (
    props
  ) => {
    const { getApiKey } = useApiKey();

    const apiKey = useMemo(() => getApiKey(), [getApiKey]);

    if (!apiKey) {
      return <Redirect to={PAGES.LOGIN} />;
    }

    return <WrappedComponent {...props} />;
  };

  ComponentWithAuthorization.displayName = `withAuthorization(${displayName})`;

  return ComponentWithAuthorization;
}

export default withAuthorization;
