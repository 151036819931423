import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import usePlaylists, { Playlist } from "../../hooks/usePlaylists";
import {
  ArrowTopRightOnSquareIcon,
  MusicalNoteIcon,
  StarIcon as StarOutlineIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { PAGES } from "../../consts";
import {
  getPlaylistStatusStyles,
  getChangeStyles,
  getPlaylistActiveStatusStyles,
} from "../../utils/playlistStyles";
import { StarIcon } from "@heroicons/react/20/solid";
import PlaylistTags from "../Playlist/PlaylistTags";
import usePlaylistStreams from "../../hooks/usePlaylistStreams";

interface PlaylistsTableRowProps {
  playlist: Playlist;
  setOpenDeleteModalPlaylist: (playlist: Playlist) => void;
}

const PlaylistsTableRow: React.FC<PlaylistsTableRowProps> = ({
  playlist,
  setOpenDeleteModalPlaylist,
}) => {
  const navigate = useNavigate();
  const { updatePlaylist } = usePlaylists();
  const { logPlaylistStreams } = usePlaylistStreams(playlist.id);
  const [editStreams, setEditStreams] = useState<boolean>(false);
  const streamsInputRef = useRef<HTMLInputElement | null>(null);

  const statusStyles = useMemo(
    () => getPlaylistStatusStyles(playlist.lastStatus),
    [playlist.lastStatus]
  );

  const isActiveStyles = useMemo(
    () => getPlaylistActiveStatusStyles(playlist.isActive),
    [playlist.isActive]
  );

  const percentChangeStyles = useMemo(
    () => getChangeStyles(playlist.numFollowersChange24hrPercent),
    [playlist.numFollowersChange24hrPercent]
  );

  const handleOpenDeleteModalPlaylist = useCallback(
    (event: React.MouseEvent, playlist: Playlist) => {
      event.stopPropagation(); // Stops the React synthetic event
      event.nativeEvent.stopImmediatePropagation(); // Stops any other native event listeners
      setOpenDeleteModalPlaylist(playlist);
    },
    [setOpenDeleteModalPlaylist]
  );

  const handleToggleFavorite = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation(); // Stops the React synthetic event
      event.nativeEvent.stopImmediatePropagation(); // Stops any other native event listeners
      updatePlaylist({ id: playlist.id, isFavorite: !playlist.isFavorite });
    },
    [playlist, updatePlaylist]
  );

  const handleViewPlaylist = (event: React.MouseEvent) => {
    event.stopPropagation(); // Stops the React synthetic event
    event.nativeEvent.stopImmediatePropagation(); // Stops any other native event listeners
    navigate(`${PAGES.PLAYLIST}/${playlist.id}`);
  };

  const handleShowEditStreams = (event: React.MouseEvent) => {
    event.stopPropagation(); // Stops the React synthetic event
    event.nativeEvent.stopImmediatePropagation(); // Stops any other native event listeners
    setEditStreams(true);
  };

  const handleSaveEditStreams = (event: React.MouseEvent) => {
    event.stopPropagation(); // Stops the React synthetic event
    event.nativeEvent.stopImmediatePropagation(); // Stops any other native event listeners

    const streams = streamsInputRef?.current?.valueAsNumber;
    if (streams && !isNaN(streams)) {
      logPlaylistStreams(streams);
      setEditStreams(false);
    }
  };

  // TODO: overflow to next line
  return (
    <tr className="cursor-pointer" onClick={handleViewPlaylist}>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm max-w-[400px] overflow-hidden sm:pl-0">
        <div className="flex items-center">
          <div
            className="relative group w-6 h-6 mr-2 cursor-pointer"
            onClick={handleToggleFavorite}
          >
            <StarOutlineIcon
              className={`absolute inset-0 w-full h-full text-yellow-400 ${
                playlist.isFavorite
                  ? "hidden group-hover:block"
                  : "group-hover:hidden"
              }`}
            />
            <StarIcon
              className={`absolute inset-0 w-full h-full  text-yellow-400 ${
                playlist.isFavorite
                  ? "group-hover:hidden"
                  : "hidden group-hover:block"
              }`}
            />
          </div>
          <div className="h-11 w-11 flex-shrink-0">
            {playlist.coverPhoto ? (
              <img
                className="h-11 w-11 rounded-full"
                src={playlist.coverPhoto}
                alt={`${playlist.coverPhoto} coverphoto`}
              />
            ) : (
              <div className="w-11 h-11 flex items-center justify-center rounded-full bg-gray-200 text-gray-500">
                <MusicalNoteIcon className="w-6 h-6 text-gray-500" />
              </div>
            )}
          </div>
          <div className="ml-4 flex-grow overflow-hidden">
            <div className="flex flex-col">
              <div className="font-medium text-gray-900">{playlist.name}</div>
              {playlist.tags?.length > 0 && (
                <PlaylistTags tags={playlist.tags} />
              )}
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {playlist.ownerName}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {playlist.followers ? playlist.followers.toLocaleString() : "Unknown"}
      </td>
      <td
        className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
        onClick={handleShowEditStreams}
      >
        {editStreams ? (
          <div className="relative mt-2 rounded-md shadow-sm">
            <input
              type="number"
              name="streams"
              id="streams"
              ref={streamsInputRef}
              min={0}
              step={1}
              className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              defaultValue={playlist.streams ?? 0}
            />
            <div
              className="absolute inset-y-0 right-0 flex items-center pr-3"
              onClick={handleSaveEditStreams}
            >
              <CheckCircleIcon
                className="h-5 w-5 text-gray-400 cursor-pointer"
                aria-hidden="true"
              />
            </div>
          </div>
        ) : (
          <>{playlist.streams ? playlist.streams.toLocaleString() : "-"}</>
        )}
      </td>
      <td
        className={`whitespace-nowrap px-3 py-4 text-sm ${percentChangeStyles}`}
      >
        {playlist.numFollowersChange24hrPercent
          ? `${playlist.numFollowersChange24hrPercent}%`
          : "--"}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {playlist.numFollowersChange24hr
          ? playlist.numFollowersChange24hr
          : "--"}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <span className="inline-flex items-center rounded-full px-3 py-1 text-sm font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20">
          {playlist.pullSchedule} hrs
        </span>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${statusStyles}`}
        >
          {playlist.lastStatus}
        </span>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${isActiveStyles}`}
        >
          {playlist.isActive ? "Active" : "Paused"}
        </span>
      </td>
      <td className="cursor-pointer relative whitespace-nowrap py-4 pl-3 text-right text-sm font-medium ">
        <div
          className="text-red-600 hover:text-red-900"
          onClick={(event: React.MouseEvent) =>
            handleOpenDeleteModalPlaylist(event, playlist)
          }
        >
          Delete
          <span className="sr-only">, {playlist.name}</span>
        </div>
      </td>
      <td className="cursor-pointer pl-2 relative whitespace-nowrap py-4 pr-4 text-right text-sm font-medium">
        <div
          className="text-indigo-600 hover:text-indigo-900"
          onClick={handleViewPlaylist}
        >
          View<span className="sr-only">, {playlist.name}</span>
        </div>
      </td>
      <td className="cursor-pointer relative whitespace-nowrap py-4 pr-4 text-right text-sm font-medium text-gray-600 hover:text-gray-900">
        <Link
          to={`https://open.spotify.com/playlist/${playlist.spotifyId}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation(); // Stops the React synthetic event
            event.nativeEvent.stopImmediatePropagation(); // Stops any other native event listeners
          }}
        >
          <ArrowTopRightOnSquareIcon className="w-4 h-4" />
        </Link>
      </td>
    </tr>
  );
};

export default memo(PlaylistsTableRow);
