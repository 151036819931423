import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import usePlaylistTags from "../../hooks/usePlaylistTags";
import { Playlist } from "../../hooks/usePlaylists";
import PlaylistTags from "../Playlist/PlaylistTags";

interface AddPlaylistTagsModalProps {
  playlist?: Playlist;
  close: () => void;
}

const removeDuplicates = (arr: string[]) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

const AddPlaylistTagsModal: React.FC<AddPlaylistTagsModalProps> = ({
  playlist,
  close,
}) => {
  const cancelButtonRef = useRef(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { addPlaylistTags, removePlaylistTags } = usePlaylistTags();
  const [allTags, setAllTags] = useState<string[]>(
    playlist ? playlist.tags : []
  );
  const [addedTags, setAddedTags] = useState<string[]>([]);

  useEffect(() => {
    setAllTags(
      playlist ? removeDuplicates([...playlist.tags, ...addedTags]) : addedTags
    );
  }, [playlist, addedTags, setAllTags]);

  const handleAddTag = useCallback(() => {
    if (playlist && inputRef?.current?.value) {
      const newTag = inputRef.current.value;

      // Cannot add a tag which already exists
      if (!allTags.includes(newTag)) {
        addPlaylistTags({
          id: playlist.id,
          tags: [newTag],
        });
        setAddedTags((prev) => [...prev, newTag]);
        inputRef.current.value = "";
      }
    }
  }, [allTags, playlist, addPlaylistTags, inputRef]);

  const handleRemoveTag = useCallback(
    (tag: string) => {
      if (playlist) {
        removePlaylistTags({ id: playlist.id, tags: [tag] });
        setAddedTags((prev) => prev.filter((t) => t !== tag));
      }
    },
    [playlist, removePlaylistTags, setAddedTags]
  );

  return (
    <Transition.Root show={playlist !== undefined} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div>
                  <button
                    onClick={close}
                    aria-label="Close"
                    className="absolute right-4 top-[1.1rem] min-w-[25px] justify-end bg-transparent p-0 text-3xl text-black hover:bg-transparent"
                  >
                    <XMarkIcon className="text-black" />
                  </button>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Add Tags
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Add tags to this playlist. Tags must be unique. To
                        remove a tag, click the 'X' icon on the tags under the
                        playlist name.
                      </p>
                    </div>
                  </div>
                </div>
                {playlist && allTags.length > 0 ? (
                  <PlaylistTags
                    playlistId={playlist.id}
                    tags={allTags}
                    allowDelete
                    deleteFn={handleRemoveTag}
                  />
                ) : (
                  <div>No tags</div>
                )}
                <div>
                  <label
                    htmlFor="tag"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    New Tag
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="tag"
                      id="tag"
                      ref={inputRef}
                      className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Tag name"
                    />
                  </div>
                  <button
                    type="button"
                    className="mt-4 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={handleAddTag}
                  >
                    Add
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default memo(AddPlaylistTagsModal);
