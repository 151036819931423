import { getApiKey } from "../utils/auth";
import { GenericGrowthRecourdRoute } from "./routes";

export const createRecord = async (playlistId: string): Promise<boolean> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${GenericGrowthRecourdRoute}/${playlistId}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getApiKey()}`,
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (res) => {
      if (res.ok) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });
};
