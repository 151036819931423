import { UseMutateFunction, useMutation, useQuery } from "react-query";
import {
  QueryKey as PlaylistDataQueryKey,
  fetchPlaylistData,
  repullFailedPlaylists,
} from "../api/playlistData";
import { useMemo } from "react";
import { useAlertContext } from "../context/AlertContext/AlertContextProvider";

export type PlaylistData = {
  id: string;
  followers: number;
  timestamp: string;
};

interface UsePlaylistDataInterface {
  playlistData: PlaylistData[];
  isLoading: boolean;
  repullFailed: UseMutateFunction<boolean, unknown, void, unknown>;
}

const usePlaylistData = (playlistId?: string): UsePlaylistDataInterface => {
  const { setAlertMessage } = useAlertContext();
  const { isLoading, data } = useQuery(
    [PlaylistDataQueryKey, playlistId],
    () => fetchPlaylistData(playlistId as string),
    { enabled: playlistId ? true : false }
  );

  const playlistData = useMemo(() => data ?? [], [data]);

  const mutateRepull = useMutation(repullFailedPlaylists, {
    onSuccess: () =>
      setAlertMessage({
        title: "Task Queued",
        message: "Repulling data for playlists with status error now",
        type: "success",
      }),
    onError: () =>
      setAlertMessage({
        title: "Error",
        message: "Unable to update. Please try again",
        type: "error",
      }),
  });

  return {
    isLoading,
    playlistData,
    repullFailed: mutateRepull.mutate,
  };
};

export default usePlaylistData;
