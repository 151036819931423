import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import AlertDialog, { AlertMessage } from "./AlertDialog";

export interface AlertContextInterface {
  setAlertMessage: (value: AlertMessage) => void;
}

export const AlertContext = createContext<AlertContextInterface>(
  {} as AlertContextInterface
);

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = memo(
  ({ children }) => {
    const [alertMessage, _setAlertMessage] = useState<AlertMessage | undefined>(
      undefined
    );

    const setAlertMessage = useCallback(
      (message: AlertMessage) => {
        _setAlertMessage(message);
        setTimeout(() => _setAlertMessage(undefined), 5000);
      },
      [_setAlertMessage]
    );

    const handleCloseAlert = useCallback(() => {
      _setAlertMessage(undefined);
    }, [_setAlertMessage]);

    return (
      <AlertContext.Provider value={{ setAlertMessage }}>
        {children}
        <AlertDialog
          show={alertMessage !== undefined}
          message={alertMessage}
          close={handleCloseAlert}
        />
      </AlertContext.Provider>
    );
  }
);

export const useAlertContext = (): AlertContextInterface =>
  useContext(AlertContext);
