import { Fragment, memo, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import usePlaylistContact, {
  PlaylistContact,
} from "../../hooks/usePlaylistContact";
import usePlaylists, { Playlist } from "../../hooks/usePlaylists";

interface EditPlaylistModalProps {
  playlist: Playlist;
  playlistContact?: PlaylistContact | null;
  close: () => void;
}

const EditPlatlistModal: React.FC<EditPlaylistModalProps> = ({
  playlist,
  playlistContact,
  close,
}) => {
  const cancelButtonRef = useRef(null);
  const { updatePlaylist } = usePlaylists();
  const { updatePlaylistContact } = usePlaylistContact(playlist.id);
  const contactNameInputRef = useRef<HTMLInputElement | null>(null);
  const contactEmailInputRef = useRef<HTMLInputElement | null>(null);
  const contactPhoneInputRef = useRef<HTMLInputElement | null>(null);
  const contactOtherInputRef = useRef<HTMLInputElement | null>(null);
  const pullscheduleInputRef = useRef<HTMLSelectElement | null>(null);

  const handleSave = () => {
    const name = contactNameInputRef?.current?.value;
    const email = contactEmailInputRef?.current?.value;
    const phone = contactPhoneInputRef?.current?.value;
    const other = contactOtherInputRef?.current?.value;
    const pullScheduleRaw = pullscheduleInputRef?.current?.value;
    const pullSchedule = pullScheduleRaw ? parseInt(pullScheduleRaw) : NaN;

    updatePlaylistContact({
      id: playlist.id,
      name,
      email,
      phone,
      other,
    });

    updatePlaylist({
      id: playlist.id,
      pullSchedule: !isNaN(pullSchedule) ? pullSchedule : undefined,
    });
    close();
  };

  return (
    <Transition.Root
      show={playlistContact !== undefined && playlist.id !== undefined}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div>
                  <button
                    onClick={close}
                    aria-label="Close"
                    className="absolute right-4 top-[1.1rem] min-w-[25px] justify-end bg-transparent p-0 text-3xl text-black hover:bg-transparent"
                  >
                    <XMarkIcon className="text-black" />
                  </button>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Edit Playlist Details
                    </Dialog.Title>
                  </div>
                </div>
                <div>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Update playlist contact details
                    </p>
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="contact-name"
                      id="contact-name"
                      ref={contactNameInputRef}
                      defaultValue={playlistContact?.name}
                      className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Name"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="email"
                      name="contact-email"
                      id="contact-email"
                      ref={contactEmailInputRef}
                      defaultValue={playlistContact?.email}
                      className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Email"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="phone"
                      name="contact-phone"
                      id="contact-phone"
                      ref={contactPhoneInputRef}
                      defaultValue={playlistContact?.phone}
                      className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Phone"
                    />
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="contact-other"
                      id="contact-other"
                      ref={contactOtherInputRef}
                      defaultValue={playlistContact?.other}
                      className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Other contact info (e.g. Instagram, Spotify ID, etc)"
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="pull-schedule"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Data Pull Schedule
                  </label>
                  <select
                    id="pull-schedule"
                    name="pull-schedule"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    ref={pullscheduleInputRef}
                    defaultValue={playlist.pullSchedule}
                  >
                    <option value={4}>4 hrs</option>
                    <option value={6}>6 hrs</option>
                    <option value={8}>8 hrs</option>
                    <option value={12}>12 hrs</option>
                    <option value={24}>24 hrs</option>
                  </select>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    onClick={handleSave}
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={close}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default memo(EditPlatlistModal);
