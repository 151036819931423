import { UseMutateFunction, useMutation, useQueryClient } from "react-query";
import { QueryKey as PlaylistsQueryKey } from "../api/playlists";
import {
  AddTagsDto,
  removePlaylistTags,
  addPlaylistTags,
  RemoveTagsDto,
} from "../api/playlistTags";

export type PlaylistTags = {
  tags: string[];
};

interface UsePlaylistTagsInterface {
  addPlaylistTags: UseMutateFunction<void, unknown, AddTagsDto, unknown>;
  removePlaylistTags: UseMutateFunction<void, unknown, RemoveTagsDto, unknown>;
}

const usePlaylistTags = (): UsePlaylistTagsInterface => {
  const queryClient = useQueryClient();

  const mutateAdd = useMutation(addPlaylistTags, {
    onSettled: () => queryClient.invalidateQueries([PlaylistsQueryKey]),
  });

  const mutateRemove = useMutation(removePlaylistTags, {
    onSettled: () => queryClient.invalidateQueries([PlaylistsQueryKey]),
  });

  return {
    addPlaylistTags: mutateAdd.mutate,
    removePlaylistTags: mutateRemove.mutate,
  };
};

export default usePlaylistTags;
