import { PlaylistTags } from "../hooks/usePlaylistTags";
import { getApiKey } from "../utils/auth";
import { PlaylistTagsRoute } from "./routes";

export const QueryKey = "playlists-contact";

export type AddTagsDto = {
  id: string;
  tags: string[];
};

export type RemoveTagsDto = {
  id: string;
  tags: string[];
};

export const fetchPlaylistTags = async (
  id: string
): Promise<PlaylistTags | null> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${PlaylistTagsRoute}/${id}`,
    {
      headers: { Authorization: `Bearer ${getApiKey()}` },
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};

export const addPlaylistTags = async (
  addPlaylistTagsDto: AddTagsDto
): Promise<void> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${PlaylistTagsRoute}/${addPlaylistTagsDto.id}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getApiKey()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tags: addPlaylistTagsDto.tags,
      }),
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};

export const removePlaylistTags = async (
  removePlaylistTagsDto: RemoveTagsDto
): Promise<void> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${PlaylistTagsRoute}/${removePlaylistTagsDto.id}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getApiKey()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tags: removePlaylistTagsDto.tags,
      }),
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};
