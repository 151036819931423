import { PlaylistData } from "../hooks/usePlaylistData";
import { getApiKey } from "../utils/auth";
import { PlaylistDataRoute } from "./routes";

export const QueryKey = "playlists-data";

export const fetchPlaylistData = async (
  id: string
): Promise<PlaylistData[]> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${PlaylistDataRoute}/${id}`,
    {
      headers: { Authorization: `Bearer ${getApiKey()}` },
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return [];
      }
    })
    .catch(() => {
      return [];
    });
};

export const repullFailedPlaylists = async (): Promise<boolean> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${PlaylistDataRoute}/repull`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getApiKey()}`,
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (res) => {
      if (res.ok) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });
};
