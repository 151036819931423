import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  AddMultiplePlaylistsDto,
  AddPlaylistDto,
  QueryKey as PlaylistsQueryKey,
  UpdatePlaylistDto,
  addPlaylist,
  bulkAddPlaylists,
  deletePlaylist,
  fetchPlaylists,
  updatePlaylist,
} from "../api/playlists";
import { useCallback, useMemo } from "react";
import { useAlertContext } from "../context/AlertContext/AlertContextProvider";

export type Playlist = {
  id: string;
  spotifyId: string;
  name: string;
  lastUpdated: Date;
  lastStatus: string;
  coverPhoto: string;
  followers: number;
  streams: number;
  numFollowersChange24hrPercent: number;
  numFollowersChange24hr: number;
  isActive: boolean;
  isFavorite: boolean;
  tags: string[];
  pullSchedule: number;
  createdAt: string;
  contactName?: string;
  contactEmail?: string;
  contactOther?: string;
  ownerId: string;
  ownerName: string;
};

interface UsePlaylistsInterface {
  playlists: Playlist[];
  isLoading: boolean;
  getPlaylist: (id?: string) => Playlist | undefined;
  addPlaylist: UseMutateFunction<void, unknown, AddPlaylistDto, unknown>;
  deletePlaylist: UseMutateFunction<boolean, unknown, string, unknown>;
  bulkAddPlaylists: UseMutateFunction<
    number,
    unknown,
    AddMultiplePlaylistsDto,
    unknown
  >;
  updatePlaylist: UseMutateFunction<void, unknown, UpdatePlaylistDto, unknown>;
}

const usePlaylists = (): UsePlaylistsInterface => {
  const queryClient = useQueryClient();
  const { setAlertMessage } = useAlertContext();

  const { isLoading, data } = useQuery(PlaylistsQueryKey, fetchPlaylists);

  const playlists = useMemo(() => data ?? [], [data]);

  const getPlaylist = useCallback(
    (id?: string) => {
      if (!id) return undefined;
      return playlists.find((p) => p.id === id);
    },
    [playlists]
  );

  const mutateAdd = useMutation(addPlaylist, {
    onSuccess: () =>
      setAlertMessage({
        title: "Successfully saved!",
        message: "Playlist added, tracking will begin now",
        type: "success",
      }),
    onError: () =>
      setAlertMessage({
        title: "Error",
        message: "Unable to add playlist. Please try again",
        type: "error",
      }),
    onSettled: () => queryClient.invalidateQueries(PlaylistsQueryKey),
  });

  const mutateBulkAdd = useMutation(bulkAddPlaylists, {
    onSuccess: (numPlaylists) =>
      setAlertMessage({
        title: "Successfully saved!",
        message: `${
          numPlaylists ?? "Unknown number of"
        } playlists added, tracking will begin now`,
        type: "success",
      }),
    onError: () =>
      setAlertMessage({
        title: "Error",
        message: "Unable to add playlists. Please try again",
        type: "error",
      }),
    onSettled: () => queryClient.invalidateQueries(PlaylistsQueryKey),
  });

  const mutateDelete = useMutation(deletePlaylist, {
    onSettled: () => queryClient.invalidateQueries(PlaylistsQueryKey),
  });

  const mutateUpdate = useMutation(updatePlaylist, {
    onMutate: (newData) => {
      queryClient.cancelQueries(PlaylistsQueryKey);

      let optimisticUpdatePlaylists = queryClient.getQueryData(
        PlaylistsQueryKey
      ) as Playlist[];

      if (optimisticUpdatePlaylists) {
        const optimisticUpdatePlaylist = optimisticUpdatePlaylists.find(
          (p) => p.id === newData.id
        );

        if (optimisticUpdatePlaylist) {
          if (newData.isActive !== undefined) {
            optimisticUpdatePlaylist.isActive = newData.isActive;
          }

          if (newData.isFavorite !== undefined) {
            optimisticUpdatePlaylist.isFavorite = newData.isFavorite;
          }
        }

        queryClient.setQueryData(PlaylistsQueryKey, optimisticUpdatePlaylists);
      }
    },
    onSettled: () => queryClient.invalidateQueries(PlaylistsQueryKey),
  });

  return {
    isLoading,
    playlists,
    getPlaylist,
    addPlaylist: mutateAdd.mutate,
    deletePlaylist: mutateDelete.mutate,
    bulkAddPlaylists: mutateBulkAdd.mutate,
    updatePlaylist: mutateUpdate.mutate,
  };
};

export default usePlaylists;
