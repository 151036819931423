import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export interface SearchContextInterface {
  tab: "all" | "favorites" | "recent";
  searchText: string;
  hasFilterApplied: boolean;
  reset: () => void;
  setTab: (tab: "all" | "favorites" | "recent") => void;
  setSearchText: (text: string) => void;
}

export const SearchContext = createContext<SearchContextInterface>(
  {} as SearchContextInterface
);

interface SearchProviderProps {
  children: ReactNode;
}

export const SearchProvider: React.FC<SearchProviderProps> = memo(
  ({ children }) => {
    const [tab, setTab] = useState<"all" | "favorites" | "recent">("all");
    const [searchText, setSearchText] = useState<string>("");

    const hasFilterApplied = useMemo(
      () => (searchText || tab === "favorites" ? true : false),
      [tab, searchText]
    );

    const resetSearch = useCallback(() => setSearchText(""), [setSearchText]);

    const reset = useCallback(() => {
      resetSearch();
      setTab("all");
    }, [resetSearch]);

    return (
      <SearchContext.Provider
        value={{
          tab,
          searchText,
          hasFilterApplied,
          setTab,
          setSearchText,
          reset,
        }}
      >
        {children}
      </SearchContext.Provider>
    );
  }
);

export const useSearchContext = (): SearchContextInterface =>
  useContext(SearchContext);
