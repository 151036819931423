import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  QueryKey as PlaylistNoteQueryKey,
  UpdateNoteDto,
  fetchPlaylistNote,
  updatePlaylistNote,
} from "../api/playlistNote";
import { useMemo } from "react";

export type PlaylistNote = {
  playlistId: string;
  text: string;
};

interface UsePlaylistNoteInterface {
  playlistNote?: string | null;
  isLoading: boolean;
  updatePlaylistNote: UseMutateFunction<void, unknown, UpdateNoteDto, unknown>;
}

const usePlaylistNote = (playlistId?: string): UsePlaylistNoteInterface => {
  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery(
    [PlaylistNoteQueryKey, playlistId],
    () => fetchPlaylistNote(playlistId as string),
    { enabled: playlistId ? true : false }
  );

  const playlistNote = useMemo(() => (data ? data.text : null), [data]);

  const mutateUpdate = useMutation(updatePlaylistNote, {
    onSettled: () =>
      queryClient.invalidateQueries([PlaylistNoteQueryKey, playlistId]),
  });

  return {
    isLoading,
    playlistNote,
    updatePlaylistNote: mutateUpdate.mutate,
  };
};

export default usePlaylistNote;
