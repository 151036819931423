import { UseMutateFunction, useMutation, useQueryClient } from "react-query";
import { QueryKey as PlaylistsQueryKey } from "../api/playlists";
import { logPlaylistStreams } from "../api/playlistStreams";

export type PlaylistStreams = {
  id: string;
  playlistId: string;
  streams: number;
  timestamp: string;
};

interface UsePlaylistStreamsInterface {
  logPlaylistStreams: UseMutateFunction<void, unknown, number, unknown>;
}

const usePlaylistStreams = (
  playlistId?: string
): UsePlaylistStreamsInterface => {
  const queryClient = useQueryClient();

  const mutateLog = useMutation(
    (streams: number) =>
      // PlaylistId should always be set
      logPlaylistStreams({ playlistId: playlistId ?? "", streams }),
    {
      onSettled: () => queryClient.invalidateQueries([PlaylistsQueryKey]),
    }
  );

  return {
    logPlaylistStreams: mutateLog.mutate,
  };
};

export default usePlaylistStreams;
