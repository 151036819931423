import { QueryClient, QueryClientProvider } from "react-query";
import { AlertProvider } from "./context/AlertContext/AlertContextProvider";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Playlist from "./pages/Playlist";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Signout from "./pages/Signout";
import { PAGES } from "./consts";
import { SearchProvider } from "./context/SearchContextProvider";

const queryClient = new QueryClient();

function App() {
  const router = createBrowserRouter([
    {
      path: PAGES.HOME,
      element: <Dashboard />,
    },
    {
      path: `${PAGES.PLAYLIST}/:id`,
      element: <Playlist />,
    },
    {
      path: PAGES.LOGIN,
      element: <Login />,
    },
    {
      path: PAGES.SIGN_OUT,
      element: <Signout />,
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <AlertProvider>
        <SearchProvider>
          <RouterProvider router={router} />
        </SearchProvider>
      </AlertProvider>
    </QueryClientProvider>
  );
}

export default App;
