import { memo } from "react";
import { MusicalNoteIcon } from "@heroicons/react/20/solid";
import useSpotifyPlaylist from "../../../hooks/useSpotifyPlaylist";

interface SpotifyPlaylistPreviewProps {
  playlistIdOrUrl: string;
}

const SpotifyPlaylistPreview: React.FC<SpotifyPlaylistPreviewProps> = ({
  playlistIdOrUrl,
}) => {
  const { isLoading, spotifyPlaylist } = useSpotifyPlaylist(playlistIdOrUrl);

  if (!spotifyPlaylist && isLoading) {
    return <></>;
  } else if (!spotifyPlaylist) {
    return <>Could not fetch playlist</>;
  }

  return (
    <div className="flex flex-col gap-2 mt-2">
      <p>Playlist Preview:</p>
      <div className="flex items-center">
        <div className="h-11 w-11 flex-shrink-0">
          {spotifyPlaylist.images?.length ? (
            <img
              className="h-11 w-11 rounded-full"
              src={spotifyPlaylist.images[0].url}
              alt={`${spotifyPlaylist.name} coverphoto`}
            />
          ) : (
            <div className="w-11 h-11 flex items-center justify-center rounded-full bg-gray-200 text-gray-500">
              <MusicalNoteIcon className="w-6 h-6 text-gray-500" />
            </div>
          )}
        </div>
        <div className="ml-4 flex-grow overflow-hidden">
          <div className="font-medium text-gray-900">
            {spotifyPlaylist.name} (
            {spotifyPlaylist.followers?.total?.toLocaleString() ?? "Unknown"}{" "}
            followers )
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SpotifyPlaylistPreview);
