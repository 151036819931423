import { memo, useCallback } from "react";
import usePlaylistTags from "../../hooks/usePlaylistTags";
import { XMarkIcon } from "@heroicons/react/20/solid";

interface PlaylistTagsProps {
  tags: string[];
  playlistId?: string;
  allowDelete?: boolean;
  deleteFn?: (tag: string) => void;
}

const PlaylistTags: React.FC<PlaylistTagsProps> = ({
  playlistId,
  tags,
  allowDelete = false,
  deleteFn,
}) => {
  const { removePlaylistTags } = usePlaylistTags();

  const handleRemoveTag = useCallback(
    (tag: string) => {
      if (allowDelete && playlistId) {
        if (deleteFn) {
          deleteFn(tag);
        } else {
          removePlaylistTags({ id: playlistId, tags: [tag] });
        }
      }
    },
    [playlistId, allowDelete, deleteFn, removePlaylistTags]
  );

  return (
    <div className="mb-2 flex gap-2">
      {tags.map((tag, i) => (
        <span
          key={i}
          className={`inline-flex items-center rounded-full px-3 py-1 text-sm font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 ${
            allowDelete &&
            "cursor-pointer hover:bg-gray-200 hover:text-gray-900 hover:ring-gray-800/20"
          }`}
          onClick={allowDelete ? () => handleRemoveTag(tag) : undefined}
        >
          {tag}
          {allowDelete && <XMarkIcon className="w-4 h-4 ml-1" />}
        </span>
      ))}
    </div>
  );
};

export default memo(PlaylistTags);
