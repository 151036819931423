import { useQuery } from "react-query";
import {
  QueryKey as SpotifyPlaylistQueryKey,
  fetchSpotifyPlaylist,
} from "../api/spotifyPlaylist";

export type SpotifyPlaylist = {
  id: string;
  name: string;
  images?: {
    url: string;
  }[];
  followers?: {
    total: number;
  };
};

interface UseSpotifyPlaylistInterface {
  isLoading: boolean;
  spotifyPlaylist?: SpotifyPlaylist | null;
}

const useSpotifyPlaylist = (
  playlistIdOrUrl?: string
): UseSpotifyPlaylistInterface => {
  const { isLoading, data: spotifyPlaylist } = useQuery(
    [SpotifyPlaylistQueryKey, playlistIdOrUrl],
    () => fetchSpotifyPlaylist(playlistIdOrUrl as string),
    { enabled: playlistIdOrUrl ? true : false }
  );

  return {
    isLoading,
    spotifyPlaylist,
  };
};

export default useSpotifyPlaylist;
