import { Fragment, memo, useCallback, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import usePlaylists, { Playlist } from "../../hooks/usePlaylists";

interface PausePlaylistModalProps {
  playlist?: Playlist;
  close: () => void;
}

const PausePlaylistModal: React.FC<PausePlaylistModalProps> = ({
  playlist,
  close,
}) => {
  const cancelButtonRef = useRef(null);
  const { updatePlaylist } = usePlaylists();

  const handleToggleActive = useCallback(() => {
    if (playlist) {
      updatePlaylist({ id: playlist.id, isActive: !playlist.isActive });
      close();
    }
  }, [playlist, close, updatePlaylist]);

  return (
    <Transition.Root show={playlist !== undefined} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div>
                  <button
                    onClick={close}
                    aria-label="Close"
                    className="absolute right-4 top-[1.1rem] min-w-[25px] justify-end bg-transparent p-0 text-3xl text-black hover:bg-transparent"
                  >
                    <XMarkIcon className="text-black" />
                  </button>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {playlist?.isActive ? "Pause" : "Unpause"} Pulling Data
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Update the active status of the playlist. When inactive
                        the system will no longer pull follower data for the
                        playlist. Click the button below to{" "}
                        {playlist?.isActive
                          ? "pause pulling data for the playlist. Existing data will not be delete."
                          : "unpause. New follower data will be pulled for the playlist."}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    onClick={handleToggleActive}
                  >
                    {playlist?.isActive ? "Pause" : "Unpause"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={close}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default memo(PausePlaylistModal);
