import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useMemo } from "react";
import {
  QueryKey as PlaylistContactQueryKey,
  UpdateContactDto,
  fetchPlaylistContact,
  updatePlaylistContact,
} from "../api/playlistContact";

export type PlaylistContact = {
  playlistId: string;
  name?: string;
  email?: string;
  phone?: string;
  other?: string;
};

interface UsePlaylistContactInterface {
  isLoading: boolean;
  playlistContact?: PlaylistContact | null;
  updatePlaylistContact: UseMutateFunction<
    void,
    unknown,
    UpdateContactDto,
    unknown
  >;
}

const usePlaylistContact = (
  playlistId?: string
): UsePlaylistContactInterface => {
  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery(
    [PlaylistContactQueryKey, playlistId],
    () => fetchPlaylistContact(playlistId as string),
    { enabled: playlistId ? true : false }
  );

  const playlistContact = useMemo(() => data ?? undefined, [data]);

  const mutateUpdate = useMutation(updatePlaylistContact, {
    onSettled: () =>
      queryClient.invalidateQueries([PlaylistContactQueryKey, playlistId]),
  });

  return {
    isLoading,
    playlistContact,
    updatePlaylistContact: mutateUpdate.mutate,
  };
};

export default usePlaylistContact;
