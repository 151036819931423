import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import usePlaylists, { Playlist as PlaylistType } from "../hooks/usePlaylists";
import Nav from "../components/Nav";
import withAuthorization from "../hoc/withAuthorization";
import usePlaylistContact, {
  PlaylistContact,
} from "../hooks/usePlaylistContact";
import {
  getPlaylistActiveStatusStyles,
  getPlaylistStatusStyles,
} from "../utils/playlistStyles";
import {
  ChartBarIcon,
  CheckCircleIcon,
  DocumentTextIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  IdentificationIcon,
  LinkIcon,
  PhoneIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import PlaylistSongs from "../components/Playlist/PlaylistSongs";
import FollowersData from "../components/Playlist/FollowersData";
import DeletePlaylistModal from "../components/Modals/DeletePlaylistModal";
import EditPlaylistModal from "../components/Modals/EditPlaylistModal";
import PausePlaylistModal from "../components/Modals/PausePlaylistModal";
import usePlaylistNote from "../hooks/usePlaylistNote";
import AddPlaylistTagsModal from "../components/Modals/AddPlaylistTagsModal";
import PlaylistTags from "../components/Playlist/PlaylistTags";
import useGenericGrowthRecord from "../hooks/useGenericGrowthRecord";
import usePlaylistStreams from "../hooks/usePlaylistStreams";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Playlist: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getPlaylist } = usePlaylists();
  const { playlistContact } = usePlaylistContact(id);
  const { logPlaylistStreams } = usePlaylistStreams(id);
  const { trackGenericGrowth } = useGenericGrowthRecord();
  const streamsInputRef = useRef<HTMLInputElement | null>(null);
  const playlistNoteRef = useRef<HTMLTextAreaElement | null>(null);
  const { playlistNote, updatePlaylistNote } = usePlaylistNote(id);
  const [editNote, setEditNote] = useState<boolean>(false);
  const [editStreams, setEditStreams] = useState<boolean>(false);
  const [openEditModalPlaylistContact, setOpenEditModalPlaylistContact] =
    useState<PlaylistContact | undefined | null>(undefined);
  const [openAddTagsModalPlaylist, setOpenAddTagsModalPlaylist] = useState<
    PlaylistType | undefined
  >(undefined);
  const [openDeleteModalPlaylist, setOpenDeleteModalPlaylist] = useState<
    PlaylistType | undefined
  >(undefined);
  const [openPauseModalPlaylist, setOpenPauseModalPlaylist] = useState<
    PlaylistType | undefined
  >(undefined);

  const playlist = useMemo(() => getPlaylist(id), [id, getPlaylist]);

  const handleCloseDeleteModalPlaylist = useCallback(() => {
    setOpenDeleteModalPlaylist(undefined);
  }, []);

  const handleCloseAddTagsModalPlaylist = useCallback(() => {
    setOpenAddTagsModalPlaylist(undefined);
  }, []);

  const handleClosePauseModalPlaylist = useCallback(() => {
    setOpenPauseModalPlaylist(undefined);
  }, []);

  const handleCloseDeleteModalPlaylistSuccess = useCallback(() => {
    navigate("/");
    setOpenDeleteModalPlaylist(undefined);
  }, [navigate]);

  const handleSavePlaylistNote = () => {
    const newNote = playlistNoteRef?.current?.value;

    if (playlist && newNote) {
      updatePlaylistNote({
        id: playlist.id,
        text: newNote,
      });
      setEditNote(false);
    }
  };

  const handleSaveStreams = () => {
    const streams = streamsInputRef?.current?.valueAsNumber;

    if (streams && !isNaN(streams)) {
      logPlaylistStreams(streams);
      setEditStreams(false);
    }
  };

  const toggleEditStreams = () => {
    setEditStreams((prev) => !prev);
  };

  const statusStyles = useMemo(
    () =>
      playlist?.lastStatus ? getPlaylistStatusStyles(playlist.lastStatus) : "",
    [playlist?.lastStatus]
  );

  const isActiveStyles = useMemo(
    () =>
      playlist?.isActive
        ? getPlaylistActiveStatusStyles(playlist.isActive)
        : "",
    [playlist?.isActive]
  );

  if (!playlist || !id) return <>Loading...</>;

  return (
    <>
      <Nav />
      <div className="w-full flex flex-col py-8 px-5">
        <div className="w-full flex">
          <div className="w-100 md:min-w-[300px] md:max-w-[300px]">
            <img
              src={playlist.coverPhoto}
              alt={`${playlist.name} coverphoto`}
            />
          </div>
          <div className="flex-grow ml-8 pt-4">
            <div className="flex justify-between items-center">
              <h1 className="text-gray-900 text-4xl font-semibold">
                {playlist.name}
              </h1>
              <div className="sm:flex sm:items-baseline sm:justify-between">
                <div className="flex items-center justify-between sm:flex-shrink-0 sm:justify-start">
                  <span className="inline-flex items-center rounded-full px-3 py-1 text-sm font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20">
                    {playlist.pullSchedule} hrs
                  </span>
                  <span
                    className={`ml-3 inline-flex items-center rounded-full px-3 py-1 text-sm font-medium ring-1 ring-inset ${statusStyles}`}
                  >
                    {playlist.lastStatus}
                  </span>
                  <span
                    className={`ml-3 inline-flex items-center rounded-full px-3 py-1 text-sm font-medium ring-1 ring-inset ${isActiveStyles}`}
                  >
                    {playlist.isActive ? "Active" : "Paused"}
                  </span>
                  <Menu
                    as="div"
                    className="relative ml-3 inline-block text-left"
                  >
                    <div>
                      <Menu.Button className="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "flex w-full justify-between px-4 py-2 text-sm"
                                )}
                                onClick={() =>
                                  setOpenEditModalPlaylistContact(
                                    playlistContact
                                  )
                                }
                              >
                                <span>Edit</span>
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "flex w-full justify-between px-4 py-2 text-sm"
                                )}
                                onClick={() =>
                                  setOpenAddTagsModalPlaylist(playlist)
                                }
                              >
                                <span>Add Tags</span>
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "flex w-full justify-between px-4 py-2 text-sm"
                                )}
                                onClick={() =>
                                  setOpenPauseModalPlaylist(playlist)
                                }
                              >
                                <span>
                                  {playlist?.isActive ? "Pause" : "Unpause"}
                                </span>
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "flex w-full justify-between px-4 py-2 text-sm"
                                )}
                                onClick={() => trackGenericGrowth(playlist.id)}
                              >
                                <span>Track "Generic Growth"</span>
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "flex w-full justify-between px-4 py-2 text-sm"
                                )}
                                onClick={() =>
                                  setOpenDeleteModalPlaylist(playlist)
                                }
                              >
                                <span>Delete</span>
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-4">
              {playlist.tags?.length > 0 && (
                <PlaylistTags
                  playlistId={playlist.id}
                  tags={playlist.tags}
                  allowDelete
                />
              )}
              <Link
                to={`https://open.spotify.com/user/${playlist.ownerId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex gap-2 items-center">
                  <p className="text-gray-800 font-semibold">Owner:</p>
                  <p>{playlist.ownerName}</p>
                </div>
              </Link>
              <div className="flex gap-2 items-center">
                <p className="text-gray-800 font-semibold">Followers:</p>
                <p>{playlist.followers.toLocaleString()}</p>
              </div>
              {playlistContact && (
                <>
                  {playlistContact.name && (
                    <div className="flex gap-2 items-center">
                      <UserIcon className="w-5 h-5 text-gray-800" />
                      <p>{playlistContact.name}</p>
                    </div>
                  )}
                  {playlistContact.email && (
                    <div className="flex gap-2 items-center">
                      <EnvelopeIcon className="w-5 h-5 text-gray-800" />
                      <p>{playlistContact.email}</p>
                    </div>
                  )}
                  {playlistContact.phone && (
                    <div className="flex gap-2 items-center">
                      <PhoneIcon className="w-5 h-5 text-gray-800" />
                      <p>{playlistContact.phone}</p>
                    </div>
                  )}
                  {playlistContact.other && (
                    <div className="flex gap-2 items-center">
                      <IdentificationIcon className="w-5 h-5 text-gray-800" />
                      <p>{playlistContact.other}</p>
                    </div>
                  )}
                </>
              )}
              <div className="flex gap-2 items-center">
                <ChartBarIcon className="w-5 h-5 text-gray-800" />
                {editStreams ? (
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="number"
                      name="streams"
                      id="streams"
                      ref={streamsInputRef}
                      min={0}
                      step={1}
                      className="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      defaultValue={playlist.streams ?? 0}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <XCircleIcon
                        className="h-5 w-5 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                        onClick={toggleEditStreams}
                      />
                      <CheckCircleIcon
                        className="h-5 w-5 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                        onClick={handleSaveStreams}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="cursor-pointer" onClick={toggleEditStreams}>
                    {playlist.streams ?? "Unknown"} Daily Streams{" "}
                    {!playlist.streams && " - click here to add one"}
                  </p>
                )}
              </div>
              <div className="flex gap-2 items-center">
                <LinkIcon className="w-5 h-5 text-gray-800" />
                <Link
                  to={`https://open.spotify.com/playlist/${playlist.spotifyId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-700 hover:text-blue-800 hover:underline"
                >
                  https://open.spotify.com/playlist/{playlist.spotifyId}
                </Link>
              </div>
              <div className="flex gap-2 items-start">
                <div className="min-w-5 min-h-5">
                  <DocumentTextIcon className="w-5 h-5 text-gray-800" />
                </div>
                <div className="flex-grow">
                  {editNote ? (
                    <>
                      <textarea
                        className="w-full"
                        placeholder="Add note here..."
                        rows={10}
                        ref={playlistNoteRef}
                        defaultValue={playlistNote ?? undefined}
                      />
                      <div className="mt-5 sm:mt-4 sm:flex gap-2 sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                          onClick={handleSavePlaylistNote}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => setEditNote(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  ) : (
                    <p
                      className="cursor-pointer"
                      onClick={() => setEditNote(true)}
                    >
                      {playlistNote
                        ? playlistNote
                        : "No note - click here to add one"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex mt-6">
          <div className="w-100 max-h-[calc(100vh-260px)] overflow-y-scroll lg:w-[40%] px-8">
            <PlaylistSongs spotifyPlaylistId={playlist.spotifyId} />
          </div>
          <div className="flex-grow max-w-100">
            <FollowersData id={id} />
          </div>
        </div>
      </div>
      <DeletePlaylistModal
        playlist={openDeleteModalPlaylist}
        close={handleCloseDeleteModalPlaylist}
        onSuccess={handleCloseDeleteModalPlaylistSuccess}
      />
      <EditPlaylistModal
        playlist={playlist}
        playlistContact={openEditModalPlaylistContact}
        close={() => setOpenEditModalPlaylistContact(undefined)}
      />
      <PausePlaylistModal
        playlist={openPauseModalPlaylist}
        close={handleClosePauseModalPlaylist}
      />
      <AddPlaylistTagsModal
        playlist={openAddTagsModalPlaylist}
        close={handleCloseAddTagsModalPlaylist}
      />
    </>
  );
};

export default withAuthorization(Playlist);
