import { memo } from "react";
import useSpotifyPlaylistTracks from "../../hooks/useSpotifyPlaylistTracks";
import { MusicalNoteIcon } from "@heroicons/react/20/solid";
import { millisToMinutesAndSeconds } from "../../utils/time";

interface PlaylistSongsProps {
  spotifyPlaylistId?: string;
}

const PlaylistSongs: React.FC<PlaylistSongsProps> = ({ spotifyPlaylistId }) => {
  const { tracks } = useSpotifyPlaylistTracks(spotifyPlaylistId);

  return (
    <>
      <h1 className="text-base font-semibold leading-6 text-gray-900">
        Songs ({tracks.length})
      </h1>
      <ul className="divide-y divide-gray-100">
        {tracks.map((track) => (
          <li key={track.id} className="py-4">
            <div className="flex items-center gap-x-3">
              {track.coverArt ? (
                <img
                  className="h-11 w-11 rounded-full"
                  src={track.coverArt}
                  alt={`${track.name} cover art`}
                />
              ) : (
                <div className="w-11 h-11 flex items-center justify-center rounded-full bg-gray-200 text-gray-500">
                  <MusicalNoteIcon className="w-6 h-6 text-gray-500" />
                </div>
              )}
              <div className="flex flex-grow items-center justify-between">
                <div>
                  <h3 className="flex-auto truncate text-md leading-6 text-gray-800">
                    {track.name}
                  </h3>
                  <p className="mt-1 truncate text-sm text-gray-500">
                    {track.album}
                    <span className="text-gray-700"> - {track.artist}</span>
                  </p>
                </div>
                <time className="flex-none text-xs text-gray-500">
                  {millisToMinutesAndSeconds(track.duration)}
                </time>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default memo(PlaylistSongs);
