import { PlaylistNote } from "../hooks/usePlaylistNote";
import { getApiKey } from "../utils/auth";
import { PlaylistNoteRoute } from "./routes";

export const QueryKey = "playlists-note";

export type CreateNoteDto = {
  text?: string | null;
};

export type UpdateNoteDto = {
  id: string;
  text?: string | null;
};

export const fetchPlaylistNote = async (
  id: string
): Promise<PlaylistNote | null> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${PlaylistNoteRoute}/${id}`,
    {
      headers: { Authorization: `Bearer ${getApiKey()}` },
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};

export const updatePlaylistNote = async (
  updatePlaylistNoteDto: UpdateNoteDto
): Promise<void> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${PlaylistNoteRoute}/${updatePlaylistNoteDto.id}`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${getApiKey()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: updatePlaylistNoteDto.text,
      }),
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};
