import { getApiKey, setApiKey } from "../utils/auth";

interface UseApiKeyInterface {
  getApiKey: () => string | null;
  setApiKey: (apiKey: string) => void;
}

const useApiKey = (): UseApiKeyInterface => {
  return { getApiKey, setApiKey };
};

export default useApiKey;
