import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { memo, useCallback } from "react";
import { useSearchContext } from "../../context/SearchContextProvider";

const Search: React.FC = () => {
  const { searchText, reset, setSearchText } = useSearchContext();

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setSearchText(event.target.value),
    [setSearchText]
  );

  return (
    <div className="relative rounded-md shadow-sm">
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-white"
            aria-hidden="true"
          />
        </div>
        <input
          type="text"
          name="search"
          id="search"
          value={searchText}
          className="block bg-gray-800 w-full rounded-md border-0 py-1.5 pl-10 text-white ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={handleSearchChange}
          placeholder="Search filter"
        />
        {searchText && (
          <button
            type="reset"
            className="absolute right-2 top-[5px] px-2 text-white"
            onClick={reset}
          >
            &times;
          </button>
        )}
      </div>
    </div>
  );
};

export default memo(Search);
