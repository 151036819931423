import { Fragment, memo, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Papa from "papaparse";
import { ArrowRightCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import usePlaylists from "../../../hooks/usePlaylists";
import { AddPlaylistDto } from "../../../api/playlists";
import SpotifyPlaylistPreview from "./SpotifyPlaylistPreview";
import BulkPlaylistTable from "./BulkPlaylistTable";

interface AddPlaylistsModalProps {
  show: boolean;
  close: () => void;
}

const AddPlaylistsModal: React.FC<AddPlaylistsModalProps> = ({
  show,
  close,
}) => {
  const cancelButtonRef = useRef(null);
  const { addPlaylist, bulkAddPlaylists } = usePlaylists();
  const [fileName, setFileName] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [contactName, setContactName] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const spotifyUrlInputRef = useRef<HTMLInputElement | null>(null);
  const contactEmailInputRef = useRef<HTMLInputElement | null>(null);
  const contactPhoneInputRef = useRef<HTMLInputElement | null>(null);
  const contactOtherInputRef = useRef<HTMLInputElement | null>(null);
  const [bulkPlaylistData, setBulkPlaylistData] = useState<AddPlaylistDto[]>(
    []
  );
  const [spotifyPlaylistPreviewIdOrUrl, setSpotifyPlaylistPreviewIdOrUrl] =
    useState<string | undefined>(undefined);

  const handleInputChange = () => {
    const file = inputRef?.current?.files ? inputRef.current.files[0] : null;
    setFileName(file?.name || "");
  };

  const handleContactNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setContactName(event.target.value);
  };

  const handleFetchSpotifyPlaylistPreview = () => {
    const playlistIdOrUrl = spotifyUrlInputRef?.current?.value;

    if (playlistIdOrUrl) {
      setSpotifyPlaylistPreviewIdOrUrl(playlistIdOrUrl);
    }
  };

  const uploadCsv = async () => {
    const input = inputRef?.current;

    if (input?.files?.length) {
      const reader = new FileReader();
      const file = input?.files[0] || [];

      reader.readAsText(file as Blob);

      reader.onload = async ({ target }) => {
        const rawData = target?.result;
        if (typeof rawData === "string") {
          const csv = Papa.parse(rawData, { header: false });
          const csvData = csv.data as string[];
          csvData.shift();

          setBulkPlaylistData(
            csvData.map((row) => ({
              spotifyUrl: row[0] as string,
              contact: row?.[0]
                ? {
                    name: row[1],
                    email: row?.[2] ? row[2] : null,
                    phone: row?.[3] ? row[3] : null,
                    other: row?.[4] ? row[4] : null,
                  }
                : undefined,
            }))
          );
        }
      };
    }
  };

  const handleAddMultiplePlaylists = () => {
    if (!bulkPlaylistData.length) return;

    setUploading(true);

    bulkAddPlaylists({ playlists: bulkPlaylistData });

    if (inputRef.current) {
      inputRef.current.value = "";
    }

    close();
    setUploading(false);
    setFileName("");
  };

  const handleAddPlaylist = () => {
    setUploading(true);
    const spotifyUrl = spotifyUrlInputRef?.current?.value;
    if (spotifyUrl) {
      close();
      addPlaylist({
        spotifyUrl,
        contact: contactName
          ? {
              name: contactName,
              email: contactEmailInputRef?.current?.value,
              phone: contactPhoneInputRef?.current?.value,
              other: contactOtherInputRef?.current?.value,
            }
          : undefined,
      });
      setSpotifyPlaylistPreviewIdOrUrl(undefined);
    } else {
      console.log("No spotify url");
    }

    setUploading(false);
  };

  // Reset preview everytime modal shows
  useEffect(() => {
    setSpotifyPlaylistPreviewIdOrUrl(undefined);
  }, [show]);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div>
                  <button
                    onClick={close}
                    aria-label="Close"
                    className="absolute right-4 top-[1.1rem] min-w-[25px] justify-end bg-transparent p-0 text-3xl text-black hover:bg-transparent"
                  >
                    <XMarkIcon className="text-black" />
                  </button>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Add New Playlist(s)
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Use this form to add a new playlist. This will pull data
                        initially and then start tracking this playlist. You can
                        optionally click{" "}
                        <span className="inline-block align-middle">
                          <ArrowRightCircleIcon
                            className="h-6 w-6 text-indigo-600 align-middle"
                            aria-hidden="true"
                          />
                        </span>{" "}
                        to show a preview of the playlist before it is added. To
                        upload multiple playlists at a time use the CSV upload
                        below.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="spotify-url"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Spotify Link*
                  </label>
                  <div className="relative mt-2">
                    <input
                      type="url"
                      name="spotify-url"
                      id="spotify-url"
                      ref={spotifyUrlInputRef}
                      className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="https://open.spotify.com/playlist/1234567890"
                    />
                    <div
                      className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
                      onClick={handleFetchSpotifyPlaylistPreview}
                    >
                      <ArrowRightCircleIcon
                        className="h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  {spotifyPlaylistPreviewIdOrUrl && (
                    <div>
                      <SpotifyPlaylistPreview
                        playlistIdOrUrl={spotifyPlaylistPreviewIdOrUrl}
                      />
                    </div>
                  )}
                  <label
                    htmlFor="contact-name"
                    className="block text-sm font-medium leading-6 mt-4 text-gray-900"
                  >
                    Contact Details
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="contact-name"
                      id="contact-name"
                      value={contactName}
                      onChange={handleContactNameChange}
                      className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Name"
                    />
                  </div>
                  {contactName && (
                    <>
                      <div className="mt-2">
                        <input
                          type="email"
                          name="contact-email"
                          id="contact-email"
                          ref={contactEmailInputRef}
                          className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Email"
                        />
                      </div>
                      <div className="mt-2">
                        <input
                          type="phone"
                          name="contact-phone"
                          id="contact-phone"
                          ref={contactPhoneInputRef}
                          className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Phone"
                        />
                      </div>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="contact-other"
                          id="contact-other"
                          ref={contactOtherInputRef}
                          className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Other contact info (e.g. Instagram, Spotify ID, etc)"
                        />
                      </div>
                    </>
                  )}
                  <button
                    type="button"
                    className="mt-4 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={handleAddPlaylist}
                  >
                    Add
                  </button>
                </div>
                <hr className="my-6 bg-slate-500" />
                <div className="mt-4 flex flex-col w-full">
                  <p className="text-sm text-gray-500">
                    To bulk add playlists upload a CSV. The columns should be
                    "Playlist URL", "Contact Name", "Contact Phone", "Contact
                    Email", and "Contact Other Details". This assumes there is a
                    header row. The only required field is "Playlist URL", if
                    contact info is provided it will be added to the record.
                    First select a file and then click "Read file". Review the
                    list of urls and then click "Add Playlists".
                  </p>
                  <div className="mt-4 flex flex-col w-full">
                    <div className="flex items-center mb-2">
                      <div className="flex items-center">
                        <button
                          className="inline-flex w-max items-center justify-center rounded-md border border-transparent bg-black px-5 py-2 text-sm font-medium text-white shadow-sm transition-colors hover:bg-[#222] focus:bg-[#222] focus:outline-none focus:ring-2 focus:ring-offset-2"
                          disabled={uploading}
                          onClick={() => inputRef?.current?.click()}
                        >
                          Select file
                        </button>
                        <p className="ml-2">{fileName}</p>
                      </div>
                      <div>
                        <input
                          ref={inputRef}
                          disabled={uploading}
                          onChange={handleInputChange}
                          type="file"
                          id="usersCsv"
                          accept=".csv"
                          className="hidden"
                        />
                        <button
                          disabled={uploading || !fileName}
                          onClick={uploadCsv}
                          className={`flex items-center justify-center rounded-md border border-transparent pl-2 text-base font-medium text-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                            !fileName && "cursor-not-allowed opacity-50"
                          }`}
                        >
                          Read File
                        </button>
                      </div>
                    </div>

                    {bulkPlaylistData.length !== 0 && (
                      <BulkPlaylistTable bulkPlaylistData={bulkPlaylistData} />
                    )}

                    <button
                      type="button"
                      className="mt-4 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={handleAddMultiplePlaylists}
                      disabled={bulkPlaylistData.length === 0}
                    >
                      Add {bulkPlaylistData.length} Playlists
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default memo(AddPlaylistsModal);
