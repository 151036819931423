import { useQuery } from "react-query";
import { useMemo } from "react";
import {
  QueryKeyTracks as SpotifyPlaylistTracksQueryKey,
  fetchSpotifyPlaylistTracks,
} from "../api/spotifyPlaylist";

export type SpotifyPlaylistTrack = {
  id: string;
  name: string;
  artist: string;
  album: string;
  coverArt: string | null;
  duration: number;
};

interface UseSpotifyPlaylistTracksInterface {
  isLoading: boolean;
  tracks: SpotifyPlaylistTrack[];
}

const useSpotifyPlaylistTracks = (
  playlistIdOrUrl?: string
): UseSpotifyPlaylistTracksInterface => {
  const { isLoading, data } = useQuery(
    [SpotifyPlaylistTracksQueryKey, playlistIdOrUrl],
    () => fetchSpotifyPlaylistTracks(playlistIdOrUrl as string),
    { enabled: playlistIdOrUrl ? true : false }
  );

  const tracks = useMemo(() => data ?? [], [data]);

  return {
    isLoading,
    tracks,
  };
};

export default useSpotifyPlaylistTracks;
