import { getApiKey } from "../utils/auth";
import { PlaylistStreamsRoute } from "./routes";

export const QueryKey = "playlists-streams";

export type LogStreamsDto = {
  playlistId: string;
  streams: number;
};

export const logPlaylistStreams = async (
  logPlaylistStreamsDto: LogStreamsDto
): Promise<void> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${PlaylistStreamsRoute}/${logPlaylistStreamsDto.playlistId}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getApiKey()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        streams: logPlaylistStreamsDto.streams,
      }),
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};
