import PlaylistsTable from "../components/PlaylistsTable";
import Nav from "../components/Nav";
import withAuthorization from "../hoc/withAuthorization";

const Dashboard: React.FC = () => {
  return (
    <>
      <Nav />
      <PlaylistsTable />
    </>
  );
};

export default withAuthorization(Dashboard);
