import { UseMutateFunction, useMutation } from "react-query";
import { createRecord } from "../api/genericGrowthRecord";
import { useAlertContext } from "../context/AlertContext/AlertContextProvider";

interface UseGenericGrowthRecordInterface {
  trackGenericGrowth: UseMutateFunction<boolean, unknown, string, unknown>;
}

const useGenericGrowthRecord = (): UseGenericGrowthRecordInterface => {
  const { setAlertMessage } = useAlertContext();

  const mutateTrack = useMutation(createRecord, {
    onSuccess: (res) =>
      setAlertMessage({
        title: res ? "Success!" : "Error",
        message: res
          ? "Successfully logged!"
          : "Unknown error occurred. Please try again",
        type: res ? "success" : "error",
      }),
    onError: () => {
      setAlertMessage({
        title: "Error",
        message: "Unable to log, please try again.",
        type: "error",
      });
    },
  });

  return {
    trackGenericGrowth: mutateTrack.mutate,
  };
};

export default useGenericGrowthRecord;
