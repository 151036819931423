export const PlaylistsRoute = "/playlists";
export const BulkPlaylistsRoute = "/playlists/bulk";
export const PlaylistDataRoute = "/playlist-data";
export const PlaylistNoteRoute = "/playlist-note";
export const PlaylistTagsRoute = "/playlist-tags";
export const PlaylistContactRoute = "/playlist-contact";
export const PlaylistStreamsRoute = "/playlist-streams";
export const SpotifyPlaylistRoute = "/playlists/spotify";
export const GenericGrowthRecourdRoute = "/generic-growth-record";
export const SpotifyPlaylistTracksRoute = "/playlists/spotify-tracks";
